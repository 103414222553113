<template>
	<div>
		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
		<!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div> -->
		<div class="top_action no_print">
			<el-button type="primary" plain icon="el-icon-download" @click="exportExcel()" style="margin-right: 10px;">Excel</el-button>
			
			<!-- <el-switch
			  v-model="showDet"
			  :active-text="$t('i18nn_78226fb265027602')"
			  inactive-text="不显示明细"
				:active-value="true"
				:inactive-value="false">
			</el-switch> -->
			
			<el-checkbox v-model="showDet">{{$t('i18nn_78226fb265027602')}}</el-checkbox>
			<el-checkbox v-model="showOrder" v-show="showDet"><span>{{$t('i18nn_d79e2ed6a2fc66fd')}}</span>&nbsp;<span>订单号</span></el-checkbox>
			<el-checkbox v-model="showTracking" v-show="showDet"><span>{{$t('i18nn_d79e2ed6a2fc66fd')}}</span>&nbsp;<span>运单号</span><span style="font-size: 12px;">(如果有值)</span></el-checkbox>
			<el-checkbox v-model="showRecevier" v-show="showDet"><span>{{$t('i18nn_d79e2ed6a2fc66fd')}}</span>&nbsp;<span>收件人</span><span style="font-size: 12px;">(如果有值)</span></el-checkbox>
			<el-checkbox v-model="showPackNo" v-show="showDet"><span>{{$t('i18nn_d79e2ed6a2fc66fd')}}</span>&nbsp;<span>入库单号</span><span style="font-size: 12px;">(如果有值)</span></el-checkbox>
			
		</div>
		<div class="printMain body-bg">
			<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
			<div style="" v-loading="loading_load">
			
				<div class="printer_con">
					<h1 class="printer_tit" style="">
						<div>Drop Shipment Picking List</div>
						<!-- <span style="padding-left: 10mm;">Order Type：</span>
						<span>{{$t('684a2afb069b6016')}}</span> -->
						
								
					</h1>
					<div class="">
						<div>
							<barcode :code="filterData.batchNo" :options="{height:50}"></barcode>
						</div>
					</div>
					
					<div class="printer_block" v-for="(itemP,indexP) in priterData" :key="indexP">
						<h2 class="printer_tit2">
							<!-- <div class="titleItem">
			          <span>order#</span>
			          <strong>{{ itemP.orderNumber }}</strong>
			        </div> -->
							<div class="titleItem">
								<span>Customer:</span>
								<strong>{{ itemP.cusName }}</strong>
							</div>
							
							<div class="titleItem">
								<span>Carrier:</span>
								<strong>{{ carrier.join(',') }}</strong>
							</div>
							<!-- <div class="titleItem">
							  <span>warehouse#</span>
							  <strong>{{ itemP.whNo }}</strong>
							</div> -->
							<!-- <div class="titleItem">
			          <span>Recevier Name#</span>
			          <strong>{{ itemP.name }}</strong>
			        </div> -->
						</h2>
			
						<div class="count-prt-con">
							<!-- <h3 class="printer_tit3">
							  <div class="titleItem">
							    <strong>Summary</strong>
							  </div>
							</h3> -->
							<table class="simTable printerTable printerTableSummary" style="">
								<tr>
									<td colspan="4">
										<div class="titleItem">
											<strong>Summary</strong>
										</div>
									</td>
								</tr>
								<tr>
									<th width="100">
										Location * PSC<br />
										库位 * 拣货数
									</th>
									<th width="100">
										SKU
									</th>
									<th width="150">
										SKU Name<br />
										SKU 名称
									</th>
									<th width="50">
										Total PSC<br />
										总件数
									</th>
									
								</tr>
								<tbody class="text_small">
									<tr v-for="(item ,index) in itemP.plSummary" :key="index" style="">
										<td>
											<div v-for="(item2,index2) in item.placeAll" :key="index2">
												<span>{{item2.location}}</span>&nbsp;*&nbsp;<strong class="text_big">{{item2.QT}}</strong>
											</div>
										</td>
										<td>
											<!-- <span>Sku:&nbsp;</span> -->
											<div><span>{{item.goodsSku}}</span></div>
											
										</td>
										<td>
											<div style="max-height:42pt; overflow:hidden;">{{item.goodsName}}</div>
											<div v-if="item.relationSkus && item.relationSkus.length>0">
												<span>
													别称
												</span>:
												<span>
													{{item.relationSkus.map(item2=>{
														return item2.relaGoodsSku
													}).join(',')}}
												</span>
											</div>
											<div v-if="!!item.ctnPCS">
												<div v-if="1==item.ctnPCS">
													<div class="border-tag">
														一箱一件
													</div>
												</div>
												<div v-else-if="item.ctnPCS>1">
													<div class="border-tag">
														<strong>一箱多件</strong>
													</div>
													<div class="border-tag">
														<strong>OPEN BOX, Send by Piece</strong>
													</div>
												</div>
											</div>
										</td>
										<td>{{item.totalSku}}</td>
										
									</tr>
								</tbody>
							</table>
						</div>
						<!-- 明细 -->
						<div v-if="showDet">
							<div  class="list-prt-con">
								<!-- <h3 class="printer_tit3">
								  <div class="titleItem">
								    <span>Order:</span>
								    <span>{{ item2.orderNumber }}</span>
								  </div>
								  <div class="titleItem">
								    <span>Recevier Name:</span>
								    <span>{{ item2.name }}</span>
								  </div>
								</h3> -->
										
								<table class="simTable printerTable">
									
									<tr>
										<th>
											<span>No.</span><br />
											<span>序号</span>
										</th>
										<th>
											<span v-show="showOrder"><span>Order-</span>订单号</span><br />
											<span v-show="showTracking"><span>Trcuking-</span>运单号</span><br />
											<span v-show="showRecevier"><span>Receiver-</span>收件人</span>
										</th>
										
										
										
										<th>
											<span>SKU</span>
										</th>
										<th>
											<span>Total PSC</span><br />
											<span>总件数</span>
										</th>
										<th>
											<span>Location</span> => <span>PSC</span><br />
											<span>库位</span> => <span>拣货数</span>
										</th>
										<th v-show="showPackNo">
											<span>In Ref No.</span><br />
											<span>入库编号</span>
										</th>
									</tr>
									
									<tbody v-for="(item2,index2) in itemP.orders" :key="index2" class="text_small">
										<tr>
											<td>
												{{index2+1}}
											</td>
											<td>
												<div v-show="showOrder">
													{{ item2.orderNumber }}
												</div>
												<div v-show="showTracking">
													<strong>
														{{ item2.tracking }}
													</strong>
												</div>
												<div v-show="showRecevier">
													{{ item2.name }}
												</div>
											</td>
											<td>
												<div v-for="(item, index) in item2.plReocrd" :key="index">
													{{item.goodsSku}}
												</div>
											</td>
											<td>
												<div v-for="(item, index) in item2.plReocrd" :key="index">
													{{item.quantity}}
												</div>
											</td>
											
											<td>
												<div v-for="(item, index) in item2.plReocrd" :key="index">
													{{item.place}}
												</div>
											</td>
											
											<td v-show="showPackNo">
												<div v-for="(item, index) in item2.plReocrd" :key="index">
													{{item.packingNo}}
												</div>
											</td>
											<!-- <td>
												<div v-for="(item, index) in item2.plReocrd" :key="index">
													
												</div>
											</td> -->
										</tr>
										<tr v-if="!!item2.remark">
											<td colspan="5">
												<div class="titleItem">
													<span>Remark#&nbsp;</span>
													<div class='pre-text'><strong>{{item2.remark}}</strong></div>
												</div>
											</td>
										</tr>
									</tbody>
									
									<!-- <tr>
										<td colspan="4">
											<div style="display: flex;">
												<div>
													<div class="titleItem">
														<strong>{{index2+1}}.</strong>
													</div>
												</div>
												<div>
													<div class="titleItem" v-show="showOrder">
														<span>Order:</span>
														<strong>{{ item2.orderNumber }}</strong>
													</div>
													<div class="titleItem" v-if="!!item2.name" v-show="showRecevier">
														<span>Recevier Name:</span>
														<strong>{{ item2.name }}</strong>
													</div>
													
													<div class="titleItem" v-if="!!item2.tracking" v-show="showTracking">
														<span>Tracking No.:</span>
														<strong>{{ item2.tracking }}</strong>
													</div>
												</div>
											</div>
											
											
										</td>
									</tr> -->
									<!-- <tbody class="text_small">
										<tr v-for="(item, index) in item2.plReocrd" :key="index" style="">
											<td width="150"><strong>Location:&nbsp;</strong><span>{{item.place}}</span></td>
											<td width="50">{{item.quantity}}</td>
											
											
											<td width="100"><strong>Sku:&nbsp;</strong><span>{{item.goodsSku}}</span></td>
											<td width="100">{{item.packingNo}}</td>
										</tr>
									</tbody> -->
									
								</table>
							</div>
						</div>
						
						
						<div class="tableFooter">
							<ul>
								<li>Picking</li>
								<li>Labeling</li>
								<li>Check</li>
							</ul>
						</div>
			
			
					</div>
			
			
			
				</div>
			
				<!-- </div> -->
				<!-- </el-card> -->
			</div>
		</div>
		

		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button></div> -->
		<!-- </el-dialog> -->
		<!-- 增值服务 -->
		<!-- 会分页-不知道为什么。其他不会 -->
		<!-- <div class="printer_break"></div> -->
		<div style="height: 10px;" class="no_print"></div>
		<div v-show="isShowValueAdded" v-if="priterData.workOrderDtos && priterData.workOrderDtos.length>0">
			<div class="printer_break"></div>
			<whPrinterValueAddListSub :openTime="ValueAddOpenTime" :tableData="priterData.workOrderDtos"></whPrinterValueAddListSub>
		</div>
		
	</div>
</template>
<script>
	// import Vue from 'vue';
	// import Print from '@/plugins/print.js';
	// Vue.use(Print); // 注册
	import excelUtilsNew from '@/utils/excelUtilsNew.js';

	import barcode from '@/components/Common/barcode.vue';
	import whPrinterValueAddListSub from '@/printer/whPrinterValueAddListSub.vue';
	export default {
		metaInfo() {
		  return {
		    title: this.filterData && this.filterData.batchNo?this.filterData.batchNo+'-Drop-Shipment-Packing-List':'HYTX-Drop-Shipment-Packing-List',
		  }
		},
		props: {
			isShowValueAdded: {
			  type: Boolean
			},
		},
		components: {
			barcode,
			whPrinterValueAddListSub
		},
		data() {
			return {
				filterData: {
					batchNo: '',
					exprStatus: ''
				},
				carrier: [],
				allData: [],
				// hasDet: '0',
				showDet: false,
				showOrder: true,
				showTracking: true,
				showRecevier: false,
				showPackNo: false,
				// listUrl: [],
				loading_load: false,
				priterData: [],
				ValueAddOpenTime:''
			};
		},
		// watch: {
		//   openTime: function(newVal, oldVal) {
		//     console.log('openTime');
		//     this.dialogFile = true;
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			if (this.$route.query && this.$route.query.batchNo) {
				this.filterData.batchNo = this.$route.query.batchNo;
			}
			if (this.$route.query && this.$route.query.exprStatus) {
				this.filterData.exprStatus = this.$route.query.exprStatus;
			}
			if (this.$route.query && this.$route.query.hasDet) {
				// this.hasDet = this.$route.query.hasDet;
				this.showDet = this.$route.query.hasDet=='0'?false:true;
			}

			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.getPageData();
			},
			// //打印
			// printerAction() {
			//   window.print();
			//   // this.$print(this.$refs.print);
			// },
			exportExcel() {
				let excelName = this.$t('i18nn_336569a8af640d81');
				let excelOption = {
					height: 20
				};
				let selHead = [{
					title: this.$t('i18nn_18d7892498938435'),
					key: 'cusName'
				}, {
					title: 'SKU',
					key: 'goodsSku'
				}, {
					title: 'SKU中文标题',
					key: 'goodsName'
				}, {
					title: 'SKU英文标题',
					key: 'goodsNameEn'
				}, {
					title: this.$t('f9db93b87e08763b'),
					key: 'totalSku'
				}, {
					title: this.$t('hytxs0000035'),
					key: 'placeAll'
				}];
				
				let selHead2 = [{
					title: this.$t('i18nn_18d7892498938435'),
					key: 'cusName'
				}, {
					title: this.$t('ae61193590bd513d'),
					key: 'orderNumber'
				}, {
					title: this.$t('e44e9a291d80c593'),
					key: 'name'
				}, {
					title: 'SKU',
					key: 'goodsSku'
				}, {
					title: this.$t('f9db93b87e08763b'),
					key: 'quantity'
				}, {
					title: this.$t('hytxs0000035'),
					key: 'place'
				}, {
					title: this.$t('i18nn_599710d641d92957'),
					key: 'packingNo'
				}];
				let excelData = [];
				let excelData2 = [];

				this.allData.map((item, index) => {
					if(item.plSummary){
						item.plSummary.map((item2, index2) => {
							excelData.push({
								cusName: item.cusName,
								goodsSku: item2.goodsSku,
								goodsName: item2.goodsName,
								goodsNameEn: item2.goodsNameEn,
								totalSku: item2.totalSku,
								placeAll: item2.placeAll,
							});
						});
					}
					
					if(item.orders){
						item.orders.map((item2, index2) => {
							if(item2.plReocrd){
								item2.plReocrd.map((item3, index3) => {
									excelData2.push({
										cusName: item.cusName,
										orderNumber: item2.orderNumber,
										name: item2.name,
										goodsSku: item3.goodsSku,
										quantity: item3.quantity,
										place: item3.place,
										packingNo: item3.packingNo,
									});
								});
							}
						});
					}
				})
				// console.log('selHead', selHead);
				// excelUtilsNew.exportExcel(selHead, this.excelData, this.excelName, this.excelOption);
				let excelSheetData = [{
					sheetName: this.$t('i18nn_743322012e31b427'),
					columns: selHead,
					Data: excelData
				}, {
					sheetName: this.$t('e6bdd31025bb4f9f'),
					columns: selHead2,
					Data: excelData2
				}];
				excelUtilsNew.exportExcelForMultiSheet(excelSheetData, excelName, excelOption);

			},
			//请求分页数据
			getPageData() {
				// let parm = [];
				// parm = this.filterData.sendId.split(",");
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.whPriterPickingListByBatchNo, {
						batchNo: this.filterData.batchNo,
						// exprStatus: this.filterData.exprStatus
					})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code && data.data) {
							if(data.data.carrier){
								//渠道
								this.carrier = data.data.carrier;
							}
							//导出用
							this.allData = data.data.prints;
							//表格显示数据
							// if (1 == this.hasDet) {
							// if(this.showDet){
								this.priterData = data.data.prints;
							// } else {
							// 	let prints = data.data.prints;
							// 	prints.forEach(item => {
							// 		item.orders = [];
							// 	})
							// 	this.priterData = prints;
							// }
							
							//增值服务
							if(this.priterData && this.priterData.workOrderDtos && this.priterData.workOrderDtos.length>0){
								this.$emit('ValAddUpdate',{
									val: this.priterData.workOrderDtos.length,
									show: true,
								});
							} else {
								this.$emit('ValAddUpdate',{
									val: 0,
									show: true,
								});
							}
							this.ValueAddOpenTime = new Date().getTime();
							
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">


</style>
<style scoped lang="less">
	// @import '@/assets/css/printer.less'
	@import url(../assets/css/printer.less);
	
</style>
